// @flow
import { useEffect } from 'react'
import styled from 'styled-components'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

import { Button, Row } from 'components/ReUsable'
import { AnalogPort, TemperaturePort, IOPort, AnalogPortForm, TemperaturePortForm } from '../hooks/useMultisense'
import { useFormik } from 'formik'
import SelectElement from 'components/ReUsable/SelectElement'
import { fillObject } from './index'

const StyledFormPage = styled.div`
  border: var(--color-table-border) 1px solid;
  border-radius: var(--dim-border-radius);
  padding: 1rem;
  min-height: 309px;
`

const StyledRow = styled(Row)`
  margin-bottom: 16px;
  min-width: 100%;
  display: flex;
  gap: 2px;

  & > label {
    font-family: open-sans-regular;
  }
  
  & > select, & > select > option {
    font-family: DigitalSerialBold, system-ui;
    color: var(--color-text);
  }

  @media (min-width: 991.98px) {
    align-items: center;
    & > label {
      flex: 0 0 34%;
    }

    & > select,
    & > .btn-group {
      flex: 0 0 66%;
      margin: 0;
    }
  }
`

const StyledButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const StyledDescription = styled.p`
  font-size: 14px;
  font-family: open-sans-regular;
`

const ANALOG_PORT = 'ANALOG_PORT'
const TEMPERATURE_PORT = 'TEMPERATURE_PORT'

export function AnalogOrTemperatureFormPage ({
  portKey,
  portConfig,
  onSubmit
}: {
  portKey: string
  portConfig: AnalogPort | TemperaturePort | IOPort | null
  onSubmit: (portKey: string, values: AnalogPortForm | TemperaturePortForm) => void
}) {
  const formik = useFormik({
    initialValues: fillObject(portKey, portConfig),
    onSubmit: (values) => {
      let submitValues = { ...values }
      if (portKey[0] === 'a' && (values as AnalogPortForm).sensor === 'custom') {
        submitValues = { ...submitValues, unit: 'volt' }
      }
      onSubmit(portKey, submitValues)
    }
  })

  useEffect(() => {
    formik.resetForm({ values: fillObject(portKey, portConfig) })
  }, [portKey, portConfig])

  const portDisabled = !formik.values.enabled || formik.values.enabled.toString() !== 'true'
  const portType = portKey[0] === 'a' ? ANALOG_PORT : TEMPERATURE_PORT
  const analogCustomSensor = portType === ANALOG_PORT && (formik.values as AnalogPortForm).sensor === 'custom'

  return (
    <>
      <form id="multisense-config-form" onSubmit={formik.handleSubmit}>
        <StyledFormPage>
          <StyledRow>
            <label>Port</label>
            <ToggleButtonGroup name="enabled" type="radio" value={formik.values.enabled.toString()}>
              <ToggleButton variant="light" id="port-option-activated" value={true.toString()} onChange={formik.handleChange}>
                ACTIVATED
              </ToggleButton>
              <ToggleButton variant="light" id="port-option-deactivated" value={false.toString()} onChange={formik.handleChange}>
                DEACTIVATED
              </ToggleButton>
            </ToggleButtonGroup>
          </StyledRow>
          {portType === ANALOG_PORT ? (
            <StyledRow>
              <label>Sensor Type</label>
              <SelectElement
                height="42px"
                name="sensor"
                value={(formik.values as AnalogPortForm).sensor}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const prevValue = (formik.values as AnalogPortForm).sensor
                  if (prevValue === 'custom' && e.target.value !== 'custom') {
                    formik.setFieldValue('unit', 'watt')
                  } else if (e.target.value === 'custom') {
                    formik.setFieldValue('unit', 'volt')
                  }
                  formik.handleChange(e)
                }}
                disabled={portDisabled}
              >
                <option value="custom">Custom</option>
                <option value="efoy150a">Current sensor 150 A</option>
                <option value="efoy50a">Current sensor 50 A</option>
                <option value="efoy10a">Current sensor 10 A</option>
              </SelectElement>
            </StyledRow>
          ) : null}
          <StyledRow>
            <label>Function</label>
            <SelectElement height="42px" name="function" value={formik.values.function} onChange={formik.handleChange} disabled={portDisabled}>
              {portType === ANALOG_PORT ? (
                <>
                  <option value="not_configured">Not configured</option>
                  <option value="custom">Custom</option>
                  <option value="solar">Solar</option>
                  <option value="battery">Battery</option>
                  <option value="load">Load</option>
                  <option value="efoy">EFOY</option>
                </>
              ) : (
                <>
                  <option value="not_configured">Not configured</option>
                  <option value="custom">Custom</option>
                  <option value="battery">Battery</option>
                  <option value="ambient">Ambient</option>
                  <option value="outdoor">Outdoor</option>
                  <option value="indoor">Indoor</option>
                  <option value="cartridge">Cartridge</option>
                </>
              )}
            </SelectElement>
          </StyledRow>
          {portType === ANALOG_PORT ? (
            <StyledRow>
              <label>Unit</label>
              <SelectElement height="42px" name="unit" value={(formik.values as AnalogPortForm).unit} onChange={formik.handleChange} disabled={portDisabled || analogCustomSensor}>
                {analogCustomSensor ? (
                  <option value="volt">V</option>
                ) : (
                  <>
                    <option value="watt">W</option>
                    <option value="ampere">A</option>
                  </>
                )}
              </SelectElement>
            </StyledRow>
          ) : null}
          {(formik.values as AnalogPortForm).unit === 'watt' ? (
            <StyledDescription>
              Calculation of the power value is done with the battery voltage. Please ensure that the measurement is taken between the battery and the solar charge controller, not directly at the
              panel!
            </StyledDescription>
          ) : null}
        </StyledFormPage>
        <StyledButtonBar>
          <Button type="submit" id="multisense-submit-button">
            Save changes
          </Button>
        </StyledButtonBar>
      </form>
    </>
  )
}
