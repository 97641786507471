// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const contactEndpoint = `${backendEndpoint || ''}/contact`

type ContactSupportPayload = {
  message: string;
  deviceName?: string;
  serialNumber?: string;
  newOwner?: string;
  currentOwner?: string;
}

export const sendContactSupportMessageRequest = (payload: ContactSupportPayload) => {
  const options = {
    method: 'POST',
    body: payload.message,
    'Content-Type': 'application/json'
  }

  if (payload.deviceName && payload.serialNumber) {
    return executeRequest(`${contactEndpoint}?deviceName=${payload.deviceName}&serialNumber=${payload.serialNumber}`, undefined, undefined, options)
  }

  if (payload.newOwner && payload.currentOwner) {
    return executeRequest(`${contactEndpoint}?newOwner=${encodeURIComponent(payload.newOwner)}&currentOwner=${encodeURIComponent(payload.currentOwner)}`, undefined, undefined, options)
  }

  return executeRequest(`${contactEndpoint}`, undefined, undefined, options)
}
