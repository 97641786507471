// @flow

import styled from 'styled-components'

type Props = {
  checked: boolean,
  onChange: (event: any) => void,
  size?: string,
};

const StyledContainer = styled.div`
  display: block !important;  
  border-radius: 2px;
  height: ${(props: any) => (props.size ? props.size : '16px')};
  width: ${(props: any) => (props.size ? props.size : '16px')};
  min-width: ${(props: any) => (props.size ? props.size : '16px')};
  border: 1px solid ${(props: any) => (props.border ? props.border : 'var(--color-background-light)')};
  cursor: pointer;
  padding: 2px;
`
const StyledContent = styled.div<{checked?: boolean}>`
  width: ${(props: any) => (props.size ? props.size * 0.6 : '10px')};
  height: ${(props: any) => (props.size ? props.size * 0.6 : '10px')};
  border-radius: 2px;
  background: ${(props: any) =>
    props.checked ? 'var(--color-primary)' : 'transparent'};
`

export default function DeviceCheckbox (props: Props) {
  const { checked, onChange, ...rest } = props

  return (
    <StyledContainer {...rest} onClick={onChange}>
      <StyledContent data-cy={checked ? 'checked' : 'unchecked'} checked={checked} />
    </StyledContainer>
  )
}
