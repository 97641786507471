//  @flow

import styled from 'styled-components'

import Form from 'components/ReUsable/Form'
import { ALERT_TYPE, Alert } from 'components/ReUsable/Alert'

import { PageSubTitle, Button, Input, Loading } from 'components/ReUsable'

import { useOwnershipTransferContact } from './hooks/useOwnershipTransferContact'
import { PrivacyCheckbox, StyledButtonContainer, StyledError, StyledRow, StyledHeaderRow, StyledTextArea, StyledResponseResultRow, StyledLoadingContainer } from './micros'

const StyledInput = styled(Input as any)`
  font-size: 14px;
  font-family: OpenSansRegular;
`

const StyledTransferNotice = styled.p`
  font-family: OpenSansRegular;
`

export default function OwnershipForm() {
  const { formik, error, success, loading } = useOwnershipTransferContact()

  return (
    <>
      <StyledHeaderRow>
        <PageSubTitle>Request for change of ownership for all EFOYs</PageSubTitle>
      </StyledHeaderRow>
      {error && (
        <StyledResponseResultRow>
          <Alert messages={[error]} type={ALERT_TYPE.danger} />
        </StyledResponseResultRow>
      )}
      {success && (
        <StyledResponseResultRow>
          <Alert messages={[success]} type={ALERT_TYPE.success} />
        </StyledResponseResultRow>
      )}
      <StyledTransferNotice>
        If you would like to transfer <b>all</b> devices from one owner to a new e-mail address (owner), please submit the following request:
      </StyledTransferNotice>
      <Form handleSubmit={formik.handleSubmit}>
        <StyledRow className="input-container">
          <StyledInput
            id="currentOwner"
            className={`form-control ${formik.errors.currentOwner && formik.touched.currentOwner ? 'input-errored' : ''}`}
            name="currentOwner"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentOwner}
            placeholder="Current owner email address*"
          />
          {formik.errors.currentOwner && formik.touched.currentOwner && <StyledError className="error">{formik.errors.currentOwner[0]}</StyledError>}
        </StyledRow>
        <StyledRow>
          <StyledInput
            id="newOwner"
            className={`form-control ${formik.errors.newOwner && formik.touched.newOwner ? 'input-errored' : ''}`}
            name="newOwner"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newOwner}
            placeholder="New owner email address*"
          />
          {formik.errors.newOwner && formik.touched.newOwner && <StyledError className="error">{formik.errors.newOwner[0]}</StyledError>}
        </StyledRow>
        <StyledRow>
          <StyledTextArea
            name="message"
            className={`form-control ${formik.errors.message && formik.touched.message ? 'input-errored' : ''}`}
            placeholder="Your message...*"
            rows={10}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          {formik.errors.message && formik.touched.message && <StyledError className="error">{formik.errors.message[0]}</StyledError>}
        </StyledRow>
        <StyledRow>
          <PrivacyCheckbox formik={formik}/>
        </StyledRow>

        <StyledButtonContainer>
          {loading ? (
            <StyledLoadingContainer>
              <Loading />
            </StyledLoadingContainer>
          ) : null}
          <Button type="submit" disabled={loading || !formik.isValid || !formik.dirty}>
            Send Form
          </Button>
        </StyledButtonContainer>
      </Form>
    </>
  )
}
