// @flow

import { useEffect, useState } from 'react'

import { getDeviceMultisense, resetDeviceMultisense, updateDeviceMultisensePort } from 'apis/devices'
import { MultiSenseType } from '../../Types/DeviceTypes'
import { getMultiSenseTypeScreenName } from '../micros'
import { delay } from '../../../../../../src/utils/helpers'
import usePageVisibility from '../../hooks/usePageVisibility'
import { POLLING_MS_INTERVAL } from 'appConstants'

export type Alert = 'enabled' | 'disabled' | 'active'

export type AnalogPort = {
  enabled: boolean
  value: number
  function: string
  alert: Alert
  sensor: string
  unit: string
  warning: Warning | null
}

export type TemperaturePort = {
  enabled: boolean
  value: number
  function: string
  alert: Alert
  unit: string
  warning: Warning | null
}

export type IOPort = {
  enabled: boolean
  function: string
  alert: Alert
  direction: 'input' | 'output'
  inverted: boolean //  function logic
  //  TODO CHECK IF OPTIONAL
  value: boolean // output value In UI inverted and value order is different then here
  currentLimit?: number
  powerOnOutput?: boolean
  emergencyOutput?: boolean | null
  warning: Warning | null
}

export type Warning = {
  major: number
  minor: number
}

export type Ports = {
  a1: AnalogPort | null
  a2: AnalogPort | null
  t1: TemperaturePort | null
  t2: TemperaturePort | null
  io1: IOPort | null
  io2: IOPort | null
  io3: IOPort | null
  io4: IOPort | null
}

export const ioPorts = ['io1', 'io2', 'io3', 'io4']

export const allPortsList = ['a1', 'a2', 't1', 't2', 'io1', 'io2', 'io3', 'io4']

export type AnalogPortForm = {
  enabled: boolean
  sensor: string
  function: string
  unit: string
}

export type TemperaturePortForm = {
  enabled: boolean
  function: string
}

export type IOPortForm = {
  enabled: boolean
  direction: 'input' | 'output'
  function: string
  inverted: boolean //  function logic
  value: boolean // output value In UI inverted and value order is different then here
  currentLimit?: number
  powerOnOutput: boolean
  errorOutput: boolean
  errorOutputEnabled: boolean // false
}

export type SuccessContent = {
  type: MultiSenseType
  ports: Ports
}

export type MultiSenseActionType = 'reset' | 'disable'

type MultiSenseHookType = {
  multiSensePorts: Ports | null
  loading: boolean
  error: string
  saveError: string
  screenName: string
  saveMultiSensePortConfig: (portKey: string, config: AnalogPortForm | TemperaturePortForm) => Promise<boolean>
  warning: Warning | null
  resetDeviceMultisenseCall: (type: MultiSenseActionType) => Promise<boolean>
}

export function useMultisense(serialNumber: string, multiSenseType: MultiSenseType): MultiSenseHookType {
  const [isPageVisible, updateDependencies] = usePageVisibility()
  const [multiSensePorts, setMultiSensePorts] = useState<Ports | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [saveError, setSaveError] = useState('')
  const [warningObj, setWarningObj] = useState<Warning | null>(null)

  //  Device Multisense API call when page is visible after 60 seconds of inactivity
  useEffect(() => {
    if (isPageVisible && updateDependencies) {
      getDeviceMultisenseCall()
    }
  }, [isPageVisible, updateDependencies])

  useEffect(() => {
    if (multiSenseType === 'none') return
    let intervalId: NodeJS.Timeout | null = null
    //  Fetch multisense immediately on mount
    getDeviceMultisenseCall()
    //  Fetch data every 60 seconds
    intervalId = setInterval(() => getDeviceMultisenseCall(), POLLING_MS_INTERVAL)

    //  Cleanup.
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])

  //  Multisense API call.
  const getDeviceMultisenseCall = async () => {
    try {
      setError('')
      setSaveError('')
      const multisenseResponse = await getDeviceMultisense(serialNumber)
      if (multisenseResponse && multisenseResponse.variant && multisenseResponse.ports) {
        const availablePorts = multisenseResponse.ports
        const ioPortsExcluded = { ...availablePorts }

        if (multisenseResponse.warning) {
          setWarningObj(multisenseResponse.warning)
        }

        //  Remove IO ports if there are present
        ioPorts.map((portName: string) => delete ioPortsExcluded[portName])
        setMultiSensePorts(multiSenseType === 'multi_sense_4' ? ioPortsExcluded : availablePorts)
        return
      } else {
        throw new Error()
      }
    } catch (e) {
      setMultiSensePorts(null)
      setError('Something went wrong.')
      return false
    } finally {
      setLoading(false)
    }
  }

  //  Multisense Port config save call.
  const saveMultiSensePortConfig = async (portKey: string, config: AnalogPortForm | TemperaturePortForm) => {
    try {
      setError('')
      setSaveError('')
      const multisenseResponse = await updateDeviceMultisensePort(serialNumber, portKey, config)
      //  Get updated list of multisense ports from backend.
      if (multisenseResponse && multisenseResponse.function) {
        //  Some delay is needed, otherwise getDeviceMultisenseCall is not updated yet.
        await delay(2000)
        await getDeviceMultisenseCall()
        return true
      } else {
        throw new Error(multisenseResponse?.detail || 'Something went wrong.')
      }
    } catch (e: any) {
      setSaveError(e.message)
      return false
    } finally {
      setLoading(false)
    }
  }

  //  Multisense Port reset or deactivate all ports call.
  const resetDeviceMultisenseCall = async (type: MultiSenseActionType) => {
    try {
      setLoading(true)
      setSaveError('')
      const multisenseResponse = await resetDeviceMultisense(serialNumber, type)
      if (multisenseResponse && multisenseResponse?.status === 204) {
        //  Some delay is needed, otherwise getDeviceMultisenseCall is not updated yet.
        await delay(2000)
        await getDeviceMultisenseCall()
        return true
      } else {
        throw new Error(multisenseResponse?.detail || 'Something went wrong.')
      }
    } catch (e: any) {
      setSaveError(e.message)
      return false
    } finally {
      setLoading(false)
    }
  }

  return { multiSensePorts, loading, error, saveError, screenName: getMultiSenseTypeScreenName(multiSenseType), saveMultiSensePortConfig, warning: warningObj, resetDeviceMultisenseCall }
}
