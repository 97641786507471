// @flow

import styled from 'styled-components'

export default styled.div`
  margin-top: 16px;
  & > div {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    padding: 0 10px;

    &:first-child {
      margin-top: 0;
    }

    & > label {
      width: 25%;
      max-width: 200px;
      font-family: open-sans-regular;
      @media (max-width: 992px) {
        width: 100%;
      }
    }

    & > div.input-container {
      position: relative;
      flex-grow: 1;
      & > .error {
        position: absolute;
        color: var(--color-alert-error);
        font-size: 0.875rem;
        font-family: open-sans-regular;
      }
    }
  }

  @media (max-width: 992px) {
    margin-top: 6px;
    & > div {
      display: block;
      margin-top: 20px;
      
      &:last-child {
        margin-bottom: 6px;
      }
    }
  }

  & > div.buttons {
    justify-content: flex-end;
  }
`
