// @flow

import { useState } from 'react'
import { useFormik } from 'formik'
import { ZodSchema, z } from 'zod'
import { sendContactSupportMessageRequest } from 'apis/contact'
import { useLocation } from 'react-router'

const validateWithZod = <T extends ZodSchema>(schema: T) => (values: unknown): Record<string, string> => {
  try {
    schema.parse(values)
    return {}
  } catch (error: any) {
    return error.formErrors.fieldErrors || {}
  }
}

const contactSchema = z.object({
  message: z.string().trim().min(1, 'Required'),
  privacyCheck: z.boolean().refine((val) => val === true, {
    message: 'Please agree to the privacy policy.'
  })
})

type FormValues = z.infer<typeof contactSchema>

const ERROR_TEXT = 'An error occured. Please try again later.'
const SUCCESS_TEXT = 'Your message has been sent.'

export function useContactForm() {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  
  const query = useQuery()
  const deviceName = query.get('deviceName') || undefined
  const serialNumber = query.get('serialNumber') || undefined
  const [success, setSuccess] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const formik = useFormik<FormValues>({
    initialValues: {
      message: '',
      privacyCheck: false
    },
    onSubmit: async (values,  { resetForm }) => {
      setLoading(true)
      setSuccess('')
      const message = values.message?.trim()
      const apiResp = await makeAPICall({ deviceName, serialNumber, message })

      if (apiResp) {
        setSuccess(SUCCESS_TEXT)
        resetForm()
      } else {
        setError(ERROR_TEXT)
        setSuccess('false')
      }
      setLoading(false)
    },
    validate: validateWithZod(contactSchema),
    validateOnChange: true,
    validateOnBlur: true
  })

  const makeAPICall = async (payload: { message: string; deviceName?: string; serialNumber?: string; }) => {
    try {
      const response = await sendContactSupportMessageRequest(payload)
      if (response.status >= 400) {
        throw new Error(ERROR_TEXT)
      } else {
        return response
      }
    } catch (e: any) {
      return null
    }
  }

  return { error, success, formik, loading, serialNumber, deviceName }
}
