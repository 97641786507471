
//  List of all permissions
export const PERMISSIONS_ALL = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'export',
  'licenses:create',
  'licenses:write',
  'notices:read',
  'notices:write',
  'users:read',
  'users:write'
]

//  No license:write but license:create permission
export const PERMISSIONS_NO_LICENSE_WRITE = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'export',
  'licenses:create',
  'users:read',
  'users:write',
  'notices:read'
]

//  license:write but no license:create permission
export const PERMISSIONS_NO_LICENSE_CREATE = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'export',
  'licenses:write',
  'users:read',
  'users:write'
]

//  license:write but no license:create permission
export const PERMISSIONS_NO_NOTICE_READ = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'export',
  'licenses:create',
  'licenses:write',
  'users:read',
  'users:write'
]

//  license:write but no license:create permission
export const PERMISSIONS_NO_NOTICE_WRITE = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'export',
  'licenses:create',
  'licenses:write',
  'notices:read',
  'users:read',
  'users:write'
]

//  license:write but no license:create permission
export const PERMISSIONS_NO_OWNERSHIP_TRANSFER = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'export',
  'licenses:create',
  'licenses:write',
  'notices:read',
  'notices:write',
  'users:read',
  'users:write'
]

//  license:write but no license:create permission
export const PERMISSIONS_OWNERSHIP_TRANSFER = [
  'devices:assign',
  'devices:read',
  'devices:write',
  'devices:owner:write',
  'export',
  'licenses:create',
  'licenses:write',
  'notices:read',
  'notices:write',
  'users:read',
  'users:write'
]

//  Provide all values as an object. In Cypress tests we will set a window variable.
//  e.g. window.USE_AUTH_MOCK_PERMISSIONS = 'PERMISSIONS_NO_LICENSE_CREATE'
//  Using this value and with the PERMISSIONS_MOCKS object, it is easy to get all permission using PERMISSIONS_MOCKS[window.USE_AUTH_MOCK_PERMISSIONS]
export const PERMISSIONS_MOCKS = { PERMISSIONS_ALL, PERMISSIONS_NO_LICENSE_WRITE, PERMISSIONS_NO_LICENSE_CREATE, PERMISSIONS_NO_NOTICE_READ, PERMISSIONS_NO_NOTICE_WRITE, PERMISSIONS_NO_OWNERSHIP_TRANSFER, PERMISSIONS_OWNERSHIP_TRANSFER }
