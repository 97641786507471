import { Col, Row, Text, CheckBox } from 'components/ReUsable'
import styled from 'styled-components'

export const DeviceDetails = ({ deviceName, serialNumber }: { deviceName?: string; serialNumber?: string }) => {
  return (
    <>
      {deviceName ? <DeviceDetailItem label="Device Name" value={deviceName} /> : null}
      {serialNumber ? <DeviceDetailItem label="Serial Number" value={serialNumber} /> : null}
    </>
  )
}

const StyledText = styled(Text)`
  margin-bottom: 0;
`

export const DeviceDetailItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <Row margin="0 0 1rem 0">
      <Col grow="1" base="20%">
        <Text>{label}</Text>
      </Col>
      <Col grow="4">
        <StyledText>{value}</StyledText>
      </Col>
    </Row>
  )
}

export const SectionContainer = styled.div`
  min-width: min(1140px, 94vw);
  background-color: var(--color-background-primary);
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    padding: 3rem;
    padding-bottom: 0;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2rem;
`

const StyledPrivacyPolicyContainer = styled.div`
  font-size: 12px;
  line-height: 22px;
  margin-top: 0.4rem;
  a {
    color: var(--color-darkest);
    text-decoration: underline;
  }
`

const StyledPrivacyPolicy = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;

  label {
    display: block;
    max-width: 760px;
  }

  input[type='checkbox'] {
    position: relative;
    top: 2px;
  }
`

export const StyledError = styled.span`
  color: var(--color-alert-error);
  font-size: 0.875rem;
  font-family: open-sans-regular;
`

export const StyledRow = styled(Row)`
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
`

export const StyledHeaderRow = styled(StyledRow)`
  margin-bottom: 1rem;
`

export const StyledTextArea = styled.textarea`
  background: none;
  font-family: open-sans-regular;
  font-size: 14px;
  min-width: 100%;
  border: 1px solid var(--color-border-light);
  border-radius: 0.375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(101, 179, 48, 0.25);
    color: var(--text-color);
    outline: 0;
  }
`

export const StyledResponseResultRow = styled(StyledRow)`
  margin-bottom: 0;
`

export const StyledLoadingContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`

export const PrivacyCheckbox = ({ formik }: { formik: any }) => {
  return (
    <>
      <StyledPrivacyPolicyContainer>
        Privacy policy *<br />
        <StyledPrivacyPolicy>
          <CheckBox
            name="privacy"
            id="privacy"
            type="checkbox"
            onChange={(e) => {
              formik.setFieldValue('privacyCheck', e.target.checked)
              // Remove error if checkbox is checked
              if (e.target.checked) {
                formik.setFieldError('privacyCheck', '')
              }
            }}
            onBlur={formik.handleBlur}
            checked={formik.values.privacyCheck}
          />
          <label htmlFor="privacy">
            Yes, I have read the{' '}
            <a href="/gdpr" target="_blank">
              data protection declaration
            </a>{' '}
            and agree that the data provided by me may be collected and stored electronically. My data will be used strictly for the purpose of processing and responding to my request. With the
            submission of this form, I agree to the processing.
          </label>
        </StyledPrivacyPolicy>
      </StyledPrivacyPolicyContainer>
      <p data-cy="privacy-check-error">{formik.errors.privacyCheck && formik.touched.privacyCheck && <StyledError className="error">{formik.errors.privacyCheck[0]}</StyledError>}</p>
    </>
  )
}
