import { Container, PageNotReachable, Row } from 'components/ReUsable'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute'
import License from './License'
import AdminNotification from './Notification'
import NavBar from './NavBar'
import useAdminRoute from './hooks/useAdminRoute'
import { DEVICE_OWNER_WRITE, LICENSE_WRITE, NOTICE_READ, USERS_READ } from './constants'
import { NotificationContextProvider } from './Notification/context/NotificationContext'
import AuditLogs from './AuditLog'
import OwnershipTransfer from './OwnershipTransfer'

export default function Admin () {
  const { path } = useRouteMatch()
  return (
    <Container>
      <Row margin='1rem 0'>
        <NavBar />
      </Row>
      <Row>
        <Switch>
          <AdminPrivateRoute
            exact={true}
            path={`${path}/licenses`}
            rights={[LICENSE_WRITE]}
            component={License}
          />
          <AdminPrivateRoute
            exact={true}
            path={`${path}/auditlogs`}
            rights={[USERS_READ]}
            component={AuditLogs}
          />
          <AdminPrivateRoute
            exact={true}
            path={`${path}/ownership`}
            rights={[DEVICE_OWNER_WRITE]}
            component={OwnershipTransfer}
          />
          <NotificationContextProvider>
            <AdminPrivateRoute
              exact={true}
              path={`${path}/notices`}
              rights={[NOTICE_READ]}
              component={AdminNotification}
            />
          </NotificationContextProvider>
          
        </Switch>
      </Row>
    </Container>
  )
}

function AdminPrivateRoute ({
  path,
  rights,
  exact,
  component: Component
}: {
  path: string
  rights: string[]
  exact?: boolean
  component: React.ElementType<any>
}) {
  const { pageAvailable, routeLoading } = useAdminRoute({ rights, path })

  if (routeLoading) return null
  if (pageAvailable) {
    return <><PrivateRoute exact={exact} path={path} component={Component} /></>
  } else {
    return <PageNotReachable />
  }
}
