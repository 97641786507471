//  @flow

import Form from 'components/ReUsable/Form'
import { ALERT_TYPE, Alert } from 'components/ReUsable/Alert'

import { PageSubTitle, Button, Loading } from 'components/ReUsable'

import { DeviceDetails, PrivacyCheckbox, StyledButtonContainer, StyledError, StyledRow, StyledHeaderRow, StyledTextArea, StyledResponseResultRow, StyledLoadingContainer } from './micros'
import { useContactForm } from './hooks/useContactForm'

export default function ContactForm() {
  const { formik, error, success, serialNumber, loading, deviceName } = useContactForm()
  return (
    <>
      <StyledHeaderRow>
        <PageSubTitle>Contact</PageSubTitle>
      </StyledHeaderRow>
      {error && (
        <StyledResponseResultRow>
          <Alert messages={[error]} type={ALERT_TYPE.danger} />
        </StyledResponseResultRow>
      )}
      {success && (
        <StyledResponseResultRow>
          <Alert messages={[success]} type={ALERT_TYPE.success} />
        </StyledResponseResultRow>
      )}
      <DeviceDetails serialNumber={serialNumber} deviceName={deviceName} />
      <Form handleSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledTextArea
            name="message"
            className={`form-control ${formik.errors.message && formik.touched.message ? 'input-errored' : ''}`}
            placeholder="Your message...*"
            rows={10}
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.errors.message && formik.touched.message && (
            <StyledError className="error" data-cy="msg-required-error">
              {formik.errors.message[0]}
            </StyledError>
          )}
        </StyledRow>
        <StyledRow>
          <PrivacyCheckbox formik={formik} />
        </StyledRow>
        <StyledButtonContainer>
          {loading ? (
            <StyledLoadingContainer>
              <Loading />
            </StyledLoadingContainer>
          ) : null}
          <Button type="submit" data-cy="submit-contact-form" disabled={loading || !formik.isValid || !formik.dirty}>
            Send Form
          </Button>
        </StyledButtonContainer>
      </Form>
    </>
  )
}
