// @flow

import { useState } from 'react'
import { useFormik } from 'formik'
import { ZodSchema, z } from 'zod'
import { updateDeviceOwnership } from 'apis/devices'

export type DeviceItem = {
  name: string
  serialNumber: string
  deviceType: string
}

export type SuccessResponse = {
  oldOwner: string
  newOwner: string
  devices: DeviceItem[]
}

const validateWithZod = <T extends ZodSchema>(schema: T) => (values: unknown): Record<string, string> => {
  try {
    schema.parse(values)
    return {}
  } catch (error: any) {
    return error.formErrors.fieldErrors || {}
  }
}

type FormValues = z.infer<typeof ownershipSchema>

const baseSchema = z.object({
  currentOwner: z.string().trim().min(1, 'Required').email('Invalid email address.'),
  newOwner: z.string().trim().min(1, 'Required').email('Invalid email address.')
})

const ownershipSchema = baseSchema.refine((data) => data.currentOwner !== data.newOwner, {
  message: 'New owner email must be different from the current owner email.',
  path: ['newOwner']
})

const ERROR_TEXT = 'An error occured. Please try again later.'

export function useOwnershipTransfer() {
  const [newOwnershipList, setNewOwnershipList] = useState<SuccessResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | number>('')
  const formik = useFormik<FormValues>({
    initialValues: {
      currentOwner: '',
      newOwner: ''
    },
    onSubmit: async (values) => {
      setLoading(true)
      const currentOwner = values.currentOwner?.toLowerCase().trim()
      const newOwner = values.newOwner?.toLowerCase().trim()

      const apiResp = await makeAPICall({ currentOwner, newOwner })

      if (apiResp) {
        setNewOwnershipList(apiResp)
      } else {
        setError(ERROR_TEXT)
        setNewOwnershipList(null)
      }
      setLoading(false)
    },
    validate: validateWithZod(ownershipSchema),
    validateOnChange: false,
    validateOnBlur: false
  })

  const makeAPICall = async (payload: { currentOwner: string; newOwner: string }) => {
    try {
      const response = await updateDeviceOwnership(payload)
      if (response.status >= 400) {
        throw new Error(ERROR_TEXT)
      } else {
        return response
      }
    } catch (e: any) {
      return null
    }
  }

  return { error, newOwnershipList, formik, loading }
}
