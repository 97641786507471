//  @flow

import { PageTitle, PageSubTitle, Text } from 'components/ReUsable'
import styled from 'styled-components'
import { SectionContainer } from './micros'


const StyledRow = styled.div`
  margin-bottom: 2rem;
`

const StyledAddress = styled.div`
  padding-bottom: .5rem;
`

const PageSubTitlePrimary = styled(PageSubTitle)`
  color: var(--color-primary)
`

export default function SupportSection() {
  return (
    <SectionContainer>
      <StyledRow>
        <PageTitle>Support</PageTitle>
      </StyledRow>
      <StyledRow>
        <PageSubTitle>Your direct contact</PageSubTitle>
        <PageSubTitlePrimary>+49 89 / 673 592-0</PageSubTitlePrimary>
      </StyledRow>
      <StyledAddress>
        <Text>
          SFC Energy AG
          <br />
          Eugen-Sänger-Ring 7<br />
          85649 Brunnthal-Nord
          <br />
          +49 89 / 673 592-0
        </Text>
      </StyledAddress>
    </SectionContainer>
  )
}
