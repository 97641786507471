import devicesSaga from './devicesSaga'
import claimsSaga from './claimsSaga'
import batteriesSaga from './batteriesSaga'
import cartridgesSaga from './cartridgesSaga'
import usersAccountSaga from './usersAccountSaga'
import groupsSaga from './groupsSaga'
import notificationsSaga from './notificationsSaga'

function * mainSaga () {
  yield * devicesSaga()
  yield * claimsSaga()
  yield * batteriesSaga()
  yield * cartridgesSaga()
  yield * usersAccountSaga()
  yield * groupsSaga()
  yield * notificationsSaga()
}

export default mainSaga
