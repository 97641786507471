import { useEffect, useRef, useState } from 'react'
import { addDays, differenceInDays, addMonths, isBefore, isAfter, format } from 'date-fns'
import { DayPicker, DateRange } from 'react-day-picker'
import styled from 'styled-components'
import 'react-day-picker/style.css'
import './styles.css'

export const StyledContainer = styled.div``

export const StyledDayPickerContainer = styled.div`
  position: absolute;
  margin-top: 0.5rem;
  background-color: var(--color-background-primary);
  z-index: 10;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  @media (prefers-color-scheme: dark) {
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
  }
`

export const StyledInput = styled.input`
  width: 21rem;
`

/**
 * @example
 * className: "",
 * intialRange: {
      from: addDays(new Date(), -3),
      to: new Date()
    },
    onRangeChange: () => void : might be the function that you want to invoke on date change
    maxRangeInDays: Maximum range you accept, for default it will go 60
    MaxDate: this is the maximum date you cannot surpass
 */
type DatePickerWithRangeProps = {
  className?: string
  initialRange?: DateRange
  onRangeChange?: (range: DateRange | undefined) => void
  maxRangeInDays?: number | 59
  maxDate?: Date
  disabled?: boolean
}

export function CustomRangeDayPicker({ initialRange, onRangeChange, maxRangeInDays = 60, maxDate = new Date(), disabled = false }: DatePickerWithRangeProps) {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>(
    initialRange ?? {
      from: addDays(new Date(), -3),
      to: new Date()
    }
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  /**
   * @desc this will control the click away function from the input
   */
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    // Add event listener when the picker is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isOpen])

  /**
   *
   * @param {DateRange} range: date range that will be transformed into string
   * @returns {string} 0: will be in a format of a date day/month/year
   */
  const formatDateRange = (range: DateRange | undefined): string => {
    // Check if range and from are valid
    if (!range?.from || !(range.from instanceof Date) || isNaN(range.from.getTime())) {
      return 'Pick a date'
    }

    // If to date exists and is valid, format range
    if (range.to && range.to instanceof Date && !isNaN(range.to.getTime())) {
      return `${format(range.from, 'LLL dd, y')} - ${format(range.to, 'LLL dd, y')}`
    }

    // If only from date is valid
    return format(range.from, 'LLL dd, y')
  }

  /**
   *
   * @param {DateRange} range: date range that will be fed into the datepicker
   * @returns void
   */
  const handleDateSelect = (range: DateRange | undefined) => {
    if (!range?.from || !range?.to) {
      setSelectedDateRange(range)
      return
    }

    const daysDifference = differenceInDays(range.to, range.from)

    if (daysDifference > maxRangeInDays) {
      const previousFrom = selectedDateRange?.from
      const previousTo = selectedDateRange?.to

      if (previousFrom && range.from.getTime() !== previousFrom.getTime()) {
        const adjustedTo = addMonths(range.from, 1)
        const finalTo = isBefore(adjustedTo, range.to) ? adjustedTo : range.to
        const adjustedRange = { from: range.from, to: finalTo }
        setSelectedDateRange(adjustedRange)
        onRangeChange?.(adjustedRange)
      }

      if (previousTo && range.to.getTime() !== previousTo.getTime()) {
        const adjustedFrom = addMonths(range.to, -1)
        const finalFrom = isAfter(adjustedFrom, range.from) ? adjustedFrom : range.from
        const adjustedRange = { from: finalFrom, to: range.to }
        setSelectedDateRange(adjustedRange)
        onRangeChange?.(adjustedRange)
      }

      return
    }

    setSelectedDateRange(range)
    onRangeChange?.(range)
  }

  /**
   *
   * @returns {date[]} : [previousMonth, currentMonth],
   * @desc we are picking the first value to have always on the left side of the date picker
   */
  const getMonths = () => {
    const currentDate = selectedDateRange?.to || new Date()
    return [addMonths(currentDate, -1), currentDate]
  }

  return (
    <StyledContainer ref={containerRef}>
      <StyledInput
        className="form-control"
        type="text"
        value={formatDateRange(selectedDateRange)}
        onClick={() => setIsOpen(!isOpen)}
        readOnly
        placeholder="Select date range"
        style={{ cursor: 'pointer' }}
        disabled={disabled}
        data-cy="date-picker-input"
      />
      {isOpen && (
        <StyledDayPickerContainer>
          <DayPicker
            selected={selectedDateRange}
            onSelect={handleDateSelect}
            dir="ltr"
            firstWeekContainsDate={1}
            fixedWeeks
            max={maxRangeInDays}
            min={1}
            mode="range"
            numberOfMonths={2}
            defaultMonth={getMonths()[0]}
            pagedNavigation
            required
            showOutsideDays
            timeZone="Europe/Berlin"
            weekStartsOn={1}
            disabled={{ after: maxDate }}
            data-cy="date-picker"
          />
        </StyledDayPickerContainer>
      )}
    </StyledContainer>
  )
}

export default CustomRangeDayPicker
