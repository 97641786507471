import styled from "styled-components"

const Checkbox = styled.input`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--color-primary);
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  border-radius: 0.25em;
  position: relative;
  margin: 0;

  &:checked {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
  }

  &:focus {
    outline: none;
  }

  @media (prefers-color-scheme: dark) {
    border: 1px solid var(--color-medium);
    background-color: var(--color-darkest);
  }
`

export default Checkbox