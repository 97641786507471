// @flow

import { useState } from 'react'
import { useFormik } from 'formik'
import { ZodSchema, z } from 'zod'
import { sendContactSupportMessageRequest } from 'apis/contact'

export type DeviceItem = {
  name: string
  serialNumber: string
  deviceType: string
}

export type SuccessResponse = {
  oldOwner: string
  newOwner: string
  devices: DeviceItem[]
}

const validateWithZod = <T extends ZodSchema>(schema: T) => (values: unknown): Record<string, string> => {
  try {
    schema.parse(values)
    return {}
  } catch (error: any) {
    return error.formErrors.fieldErrors || {}
  }
}

type FormValues = z.infer<typeof ownershipSchema>

const baseSchema = z.object({
  currentOwner: z.string().trim().min(1, 'Required').email('Invalid email address.'),
  newOwner: z.string().trim().min(1, 'Required').email('Invalid email address.'),
  message: z.string().trim().min(1, 'Required'),
  privacyCheck: z.boolean().refine(val => val === true, {
    message: "Please agree to the privacy policy."
  }) 
})

const ownershipSchema = baseSchema.refine((data) => data.currentOwner !== data.newOwner, {
  message: 'New owner email must be different from the current owner email.',
  path: ['newOwner']
})

const ERROR_TEXT = 'An error occured. Please try again later.'
const SUCCESS_TEXT = 'Request has been sent successfully.'

export function useOwnershipTransferContact() {
  const [success, setSuccess] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const formik = useFormik<FormValues>({
    initialValues: {
      currentOwner: '',
      newOwner: '',
      message: '',
      privacyCheck: false
    },
    onSubmit: async (values,  { resetForm }) => {
      setLoading(true)
      setSuccess('')
      const currentOwner = values.currentOwner?.toLowerCase().trim()
      const newOwner = values.newOwner?.toLowerCase().trim()
      const message = values.message?.trim()
      const apiResp = await makeAPICall({ currentOwner, newOwner, message })

      if (apiResp) {
        setSuccess(SUCCESS_TEXT)
        resetForm()
      } else {
        setError(ERROR_TEXT)
        setSuccess('false')
      }
      setLoading(false)
    },
    validate: validateWithZod(ownershipSchema),
    validateOnChange: true,
    validateOnBlur: true
  })

  const makeAPICall = async (payload: { currentOwner: string; newOwner: string; message: string }) => {
    try {
      const response = await sendContactSupportMessageRequest(payload)
      if (response.status >= 400) {
        throw new Error(ERROR_TEXT)
      } else {
        return response
      }
    } catch (e: any) {
      return null
    }
  }

  return { error, success, formik, loading }
}
