import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import dialogReducer from './dialogReducer'
import devicesReducer from './devicesReducer'
import batteryReducer from './batteryReducer'
import alertReducer from './alertReducer'
import usersAccountReducer from './usersAccountReducer'
import requestReducer from './requestReducer'
import groupsReducer from './groupsReducer'
import successReducer from './successReducer'
import errorReducer from './errorReducer'
import notificationsReducer from './notificationReducer'
import uiStateReducer from './uiStateReducer'
import graphReducer from './graphReducer'

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    dialog: dialogReducer,
    devices: devicesReducer,
    battery: batteryReducer,
    alert: alertReducer,
    userAccount: usersAccountReducer,
    request: requestReducer,
    groups: groupsReducer,
    success: successReducer,
    errors: errorReducer,
    notifications: notificationsReducer,
    uiState: uiStateReducer,
    graphReducer: graphReducer
  })

export default rootReducer
