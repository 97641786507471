//  @flow

import styled from 'styled-components'
import SupportSection from './supportSection'
import ContactForm from './contactForm'
import { SectionContainer } from './micros'
import { OutlineButton } from 'components/ReUsable'
import { useState } from 'react'
import OwnershipForm from './ownershipForm'

const StyledSupportPage = styled.div`
  max-width: 1140px;
  margin: 1rem 1rem 0 1rem;

  @media (min-width: 992px) {
    margin: 3rem auto 0 auto;
  }
`

const StyledFormSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.6rem;
`

const BTN_BACK_TO_CONTACT_FORM = 'Back to contact form'
const BTN_OWNER_SUPPORT = 'Owner support'

export default function Contact() {
  const [ownershipFormActive, setownershipFormActive] = useState<boolean>(false)

  const btnText = ownershipFormActive ? BTN_BACK_TO_CONTACT_FORM : BTN_OWNER_SUPPORT

  return (
    <StyledSupportPage>
      <SupportSection />
      <SectionContainer>
        { ownershipFormActive ? <OwnershipForm /> : <ContactForm /> }
        <StyledFormSwitch>
          <OutlineButton data-cy="switch-to-ownership-transfer" type="button" onClick={() => setownershipFormActive(!ownershipFormActive)}>
            { btnText }
          </OutlineButton>
        </StyledFormSwitch>
      </SectionContainer>
    </StyledSupportPage>
  )
}
