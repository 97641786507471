import styled from 'styled-components'

import useCheckAuth0ScopePermissions from '../hooks/useCheckAuth0ScopePermissions'
import { DEVICE_OWNER_WRITE } from '../constants'
import { Container, PageTitle, PageNotReachable, Row, Col, Label, Input, Button, SimpleForm, Loading, PageSubTitle } from 'components/ReUsable'
import { StyledAdminCard } from 'components/ReUsable/Card'
import { useOwnershipTransfer, DeviceItem, SuccessResponse } from './hooks/useOwnershipTransfer'

const StyledOwnershipCard = styled(StyledAdminCard)`
  min-height: 16rem;

  @media (max-width: 992px) {
    min-height: auto;
  }
`

const StyledErrorSpan = styled.span`
  color: var(--color-error-text);
  font-size: 1rem;
  margin-right: 2rem;
`

const StyledLoadingContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`

const OwnershipTransfer = () => {
  const { allow: allowWrite } = useCheckAuth0ScopePermissions({ rights: [DEVICE_OWNER_WRITE] })
  const { formik, error, newOwnershipList, loading } = useOwnershipTransfer()
  //  Page only visible for users with devices:owner:write rights.
  if (!allowWrite) return <PageNotReachable />

  return (
    <Container data-cy="admin-ownership-transfer">
      <Row margin="1rem 0">
        <Col>
          <PageTitle>Ownership Transfer</PageTitle>
        </Col>
      </Row>
      <Row>
        <StyledOwnershipCard>
          {newOwnershipList ? (
            <OwnershipList responseObj={newOwnershipList} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <SimpleForm>
                <div>
                  <Label for="currentOwner">Current owner</Label>
                  <div className="input-container">
                    <Input
                      id="currentOwner"
                      className={`form-control ${formik.errors.currentOwner ? 'input-errored' : ''}`}
                      name="currentOwner"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.currentOwner}
                    />
                    {formik.errors.currentOwner && <span className="error">{formik.errors.currentOwner[0]}</span>}
                  </div>
                </div>
                <div>
                  <Label for="newOwner">New owner</Label>
                  <div className="input-container">
                    <Input
                      id="newOwner"
                      className={`form-control ${formik.errors.newOwner ? 'input-errored' : ''}`}
                      name="newOwner"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.newOwner}
                    />
                    {formik.errors.newOwner && <span className="error">{formik.errors.newOwner[0]}</span>}
                  </div>
                </div>
                <div className="buttons">
                  {error ? <StyledErrorSpan data-cy="ownership-transfer-error">{error}</StyledErrorSpan> : null}
                  {loading ? (
                    <StyledLoadingContainer style={{ width: '30px', height: '30px' }}>
                      <Loading />
                    </StyledLoadingContainer>
                  ) : null}
                  <Button id="ownership-transfer-submit-button" type="submit" disabled={loading}>
                    Transfer
                  </Button>
                </div>
              </SimpleForm>
            </form>
          )}
        </StyledOwnershipCard>
      </Row>
    </Container>
  )
}

export const StyledResultHeader = styled(PageSubTitle)`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
`

const StyledSubheader = styled.p`
  font-family: open-sans-regular;
  margin-bottom: 10px;
`

const StyledListItem = styled.p`
  font-family: open-sans-regular;
  margin-bottom: 0;
`

const OwnershipList = ({ responseObj }: { responseObj: SuccessResponse }) => {
  const { oldOwner, newOwner, devices } = responseObj
  const headerText = `<b>${devices.length}</b> EFOY${devices.length > 1 ? 's' : ''} are successfully transfered from <b>${oldOwner}</b> to <b>${newOwner}</b>.`
  return (
    <>
      <StyledResultHeader data-cy="ownership-transfer-success-header">Results for ownership transfer</StyledResultHeader>
      <StyledSubheader dangerouslySetInnerHTML={{ __html: headerText }}></StyledSubheader>
      {devices.map((device: DeviceItem) => (
        <StyledListItem key={device.serialNumber}>{`- ${device.serialNumber} (${device.name})`}</StyledListItem>
      ))}
    </>
  )
}

export default OwnershipTransfer
